import React from "react";
import { cn } from "@/app/_utils/ui";

const LoadingAnimation = ({
  children,
  className,
}: {
  children?: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={cn(
        "flex-1 flex gap-2 justify-center items-center h-5",
        className,
      )}
    >
      <svg
        className="animate-spin h-5 w-5 text-red-600"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 0116 0H4z"
        ></path>
      </svg>
      {children}
    </div>
  );
};

export default LoadingAnimation;
