import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { READING_LISTS } from "@/app/_consts/internal";

interface ReadingList {
  [key: string]: any; // Replace 'any' with a more specific type for your reading lists
}

interface ReadingListsState {
  isLoggedIn: boolean;
  isLoading: boolean;
  readingLists: ReadingList;
}

const initialState: ReadingListsState = {
  isLoggedIn: false,
  isLoading: true,
  readingLists: {},
};

export const fetchReadingLists = createAsyncThunk<any, string>(
  "readingLists/fetchReadingLists",
  async (accessToken) => {
    const response = await axios.get(READING_LISTS, {
      headers: { Authorization: accessToken },
    });
    return response.data;
  },
);

const readingListsSlice = createSlice({
  name: "readinglists",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReadingLists.pending, (state) => {
        state.readingLists = {};
        state.isLoading = true;
        state.isLoggedIn = false;
      })
      .addCase(fetchReadingLists.fulfilled, (state, action) => {
        const newReadingLists: any = {};
        action.payload.forEach((readingList: any) => {
          newReadingLists[readingList.id] = readingList;
        });
        state.readingLists = newReadingLists;
        state.isLoading = false;
        state.isLoggedIn = action.payload.length > 0;
      })
      .addCase(fetchReadingLists.rejected, (state) => {
        state.isLoading = false;
        state.isLoggedIn = false;
      });
  },
});

export default readingListsSlice.reducer;
