import {
  ANALYTICS,
  ANALYTICS_SHARE,
  ARTICLE_BY_ID_URL,
} from "@/app/_consts/internal";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { DailyStat, ShareStat } from "../views/analytics/types";

const initialState = {
  isLoading: true,
  dailyStats: [] as DailyStat[],
  shareStats: [] as ShareStat[],
  searchReport: null,
};

export const fetchAnalytics = createAsyncThunk<
  { daily_stats: DailyStat[] },
  { accessToken: string | null; start: string; end: string }
>("analytics/fetchAnalytics", async ({ accessToken, start, end }) => {
  const response = await axios.get(`${ANALYTICS}?start=${start}&end=${end}`, {
    headers: { Authorization: accessToken },
  });
  return response.data;
});

export const fetchAnalyticsShare = createAsyncThunk<
  { share_stats: ShareStat[] },
  { accessToken: string | null; start: string; end: string }
>("analytics/fetchAnalyticsShare", async ({ accessToken, start, end }) => {
  const response = await axios.get(
    `${ANALYTICS_SHARE}?start=${start}&end=${end}`,
    {
      headers: { Authorization: accessToken },
    },
  );
  return response.data;
});

export const searchArticle = createAsyncThunk<any, any>(
  "analytics/searchArticle",
  async (data) => {
    const { accessToken, articleId, search } = data;
    const response = await axios.post(
      `${ARTICLE_BY_ID_URL}/${articleId}/search`,
      { search },
      { headers: { Authorization: accessToken } },
    );

    return {
      articleId,
      Status: response.data.Status,
      searchArticle: response.data,
    };
  },
);

export const fetchSearchReport = createAsyncThunk<
  any,
  { accessToken: string; articleId: string; start: string; end: string }
>("analytics/searchReport", async ({ accessToken, articleId, start, end }) => {
  const response = await axios.get(
    `${ARTICLE_BY_ID_URL}/${articleId}/search?start=${start}&end=${end}`,
    { headers: { Authorization: accessToken } },
  );
  return response.data;
});

const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAnalytics.pending, (state) => {
        state.dailyStats = [];
        state.isLoading = true;
      })
      .addCase(fetchAnalytics.fulfilled, (state, action) => {
        state.dailyStats = action.payload.daily_stats;
        state.isLoading = false;
      })
      .addCase(fetchAnalytics.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchAnalyticsShare.fulfilled, (state, action) => {
        state.shareStats = action.payload.share_stats;
        state.isLoading = false;
      })
      .addCase(fetchSearchReport.fulfilled, (state, action) => {
        state.searchReport = action.payload;
      })
      .addCase(fetchSearchReport.rejected, (state, action) => {
        state.searchReport = action.payload as never;
      });
  },
});

export default analyticsSlice.reducer;
